import React, {
  createContext,
  useReducer,
  useContext,
  useCallback
} from 'react';
import { IPlatformCardProps } from '../components/molecules/Card/IPlatformCard';

type CardStore = {[key: string]: IPlatformCardProps}
interface AppListInitialStates {
  store: CardStore
}
type ApiCardItem = {
  title: string;
  link: string;
}

export type IBasket =  ApiCardItem []

export enum ACTION_TYPES {
  ADD_TO_LIST = 'ADD_TO_LIST',
  REMOVE_FROM_LIST = 'REMOVE_FROM_LIST',
  CLEAR_CART = 'CLEAR_CART'
}

type AppListActions =
  | { type: ACTION_TYPES.ADD_TO_LIST, payload: IPlatformCardProps }
  | { type: ACTION_TYPES.REMOVE_FROM_LIST, payload: IPlatformCardProps }
  | { type: ACTION_TYPES.CLEAR_CART }


  const getInitialState = (): AppListInitialStates => {
    try {
      // check if timesrtamp is expired after 3 days
      const expiryDate = parseInt(localStorage.getItem('cardinalStoreExpiryDate') || '0');
      if (Date.now() - expiryDate > 259200000) {
          localStorage.removeItem('cardinalStoreAppList');
          localStorage.removeItem('cardinalStoreExpiryDate');
          return ({
            store: {}
          })
      }
      return JSON.parse(localStorage.cardinalStoreAppList);
    } catch (error) {
      return ({
        store: {}
      })
    }
  }

  const saveStateInLocalStorage = (state: AppListInitialStates) => {
    localStorage.setItem('cardinalStoreAppList', JSON.stringify(state))
    localStorage.setItem('cardinalStoreExpiryDate', Date.now().toString())
  }

function reducer(prevState: AppListInitialStates, action: AppListActions) {
  switch (action.type) {
    case ACTION_TYPES.ADD_TO_LIST:
    {
      const newState = {store: { ...prevState.store, [action?.payload?.uniqueReference]: action?.payload }};
      saveStateInLocalStorage(newState)
      return newState
    };
    case ACTION_TYPES.REMOVE_FROM_LIST:
    {
        const newStore = { ...prevState.store}
        if(prevState?.store[action?.payload?.uniqueReference])  {
          delete newStore[action?.payload?.uniqueReference]; 
        }
        const newState = { store: newStore };
        saveStateInLocalStorage(newState)
        return newState;
    }
    case ACTION_TYPES.CLEAR_CART:
    {
        const newState = {store: {}};
        saveStateInLocalStorage(newState)
        return newState
     };
  }
}

export type IAppListRecords = {
  store?: CardStore;
  storeLength: number;
  onAppListClick?: (rec: IPlatformCardProps) => void;
  isInAppList?: (inp: string) => boolean;
  getApps?:() => IPlatformCardProps [];
  getApiData?: () => any;
  clearCart?: () => void;
}


const AppListContext = createContext<IAppListRecords>({
  storeLength: 0,
});

const AppListProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const onAppListClick = useCallback(
    (record: IPlatformCardProps) => {
      
      if(state?.store[record?.uniqueReference]) {
        dispatch({
          type: ACTION_TYPES.REMOVE_FROM_LIST,
          payload: record
        })
      } else {
        dispatch({
          type: ACTION_TYPES.ADD_TO_LIST,
          payload: record
        })
      }
    },
    [state?.store],
  ) 

  const isInAppList = useCallback(
    (uniqueReference: string) => {
      return !!state.store[uniqueReference]
    },
    [state?.store],
  ) ;

  const getApps = useCallback((): IPlatformCardProps[] => {
    return Object.values(state.store);
  }, [state?.store])

  const getApiData = useCallback(
    () => {
      const store = Object.keys(state?.store);
      const apiData: ApiCardItem[] = [];
      store.forEach(key => {
        const record: IPlatformCardProps = state?.store[key];
        apiData?.push({title: record?.title, link: record?.ctaUrl || ""});
      });
      return apiData;
    },
    [state?.store],
  );

  const clearCart = useCallback(
    () => {
      dispatch({
        type: ACTION_TYPES.CLEAR_CART
      })
    },  
    [],
  );


  const AppListRecords: IAppListRecords = {
    store: state.store,
    storeLength: Object.keys(state.store)?.length || 0,
    onAppListClick,
    isInAppList,
    getApps,
    getApiData,
    clearCart
  }

  return (
    <AppListContext.Provider value={AppListRecords}>
      {children}
    </AppListContext.Provider>
  )
}


const useAppListContext = () => {
  return useContext(AppListContext);
}

export { useAppListContext, AppListProvider };
