import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useCallback,
} from "react"
import { IPlatformCardProps } from "../components/molecules/Card/IPlatformCard"
import { ISelectedChip } from "../components/organism/ItemSelectWrapper";
import { usePlatformPoltfolioData } from "../hooks/usePlatformPoltfolioData"

type keyname =  string;

type ImageRec = {
  asset: {
    _id: string;
    url: string;
    extension: string;
  }
}

type IPlatformGroups = {
  name?: string;
  "_id": string;
  "_type": string;
  title?: string;
  description?: {
    children: {
      text: string;
    }[]
  }[]
  image?: ImageRec
}
type ICategories = {
  totalCount: number;
  list:{ category: IPlatformGroups}[]
}

type IPAS = {
  totalCount?: number;
  list?:{ pas: IPlatformGroups}[]
}

type ITerritories = {
  totalCount?: number;
  list?:{ territory: IPlatformGroups}[]
}

type IProvider = {
  totalCount?: number;
  list?:{ provider: IPlatformGroups}[]
};

export interface IProductRec {
    _id: string;
    _type: string;
    name: string;
    isComingSoon?: boolean;
    description: string;
    screenshots?: ImageRec [];
    contactEmail?: string;
    contactName?: string;
    billingMethodology?: string;
    category?: IPlatformGroups [];
    categoryList?: string[];
    pasIntegration?: IPlatformGroups [];
    pasIndependent?: boolean;
    mostPopularApp?: boolean;
    recentlyAddedApp?: boolean;
    pasIntegrationList?: string[];
    video?: {
      autoplay?: boolean;
      controls?: boolean;
      ["_type"]?: string;
      url?: string;
      title?: string;
    };
    territory?: IPlatformGroups [];
    territoryList?: string[];
    provider?: IPlatformGroups;
    providerList?: string[];
    image?: ImageRec;
    slug?: {
      current: string;
    }
}

interface IProductRaw  {
  totalCount?: number;
  list?:{ product: IProductRec}[];
}

interface IProduct  {
  totalCount?: number;
  list?: IProductRec[];
  comingSoonList?: IProductRec[];
}

type IPlatformStoreRecord = IProvider | ICategories | IPAS | ITerritories | IProduct | IProductRaw;

type IPlatformStore = { [key: keyname]: IPlatformStoreRecord }

interface IPlatformInitialStates {
  store: IPlatformStore
}

export enum ACTION_TYPES {
  INITIALIZE_STORE = "INITIALIZE_STORE",
}

type IPlatformActions =
  | { type: ACTION_TYPES.INITIALIZE_STORE; payload: IPlatformStore }

const getInitialState = (): IPlatformInitialStates => {
    return ({
      store: {},
    })
}

export type IChip = {
  category: string;
  list: ISelectedChip[];
  title: string;
  id?: string;
}

export type IChipListArr = IChip[];

function reducer(prevState: IPlatformInitialStates, action: IPlatformActions) {
  switch (action.type) {
    case ACTION_TYPES.INITIALIZE_STORE: {
      const newState = {
        store: action.payload
      }
      return newState
    }
    default:
      return prevState
    }
  }

export type IPlatformRecords = {
  store?: any;
  getCategories?: () => any;
  getRecentlyAddedApp?: () => any;
  getMostPopularApp?: () => any;
  getChips?: () => any;
  getFilteredProducts?: (chipArr: ISelectedChip[]) => any;
  getProductByID?: (id: string) => any;
  getCountries?: () => any;
  getCategoryChipById?: (id: string) => any;
  getSearchResult?: (searchText: string) => any;
  getComingSoonApp?: () => any;
}

const IPlatformContext = createContext<IPlatformRecords>({})

const IPlatformProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { data } = usePlatformPoltfolioData();

  const sortProductsAlphabetically = (products: IProductRec[]): IProductRec[] => {
    const result = products?.sort?.((a, b) => {
      if(a?.name?.toLowerCase?.() && b?.name?.toLowerCase?.()) {
        if ( a?.name?.toLowerCase?.() < b?.name?.toLowerCase?.()) {
          return -1
        }
        if (a?.name?.toLowerCase?.() > b?.name?.toLowerCase?.()) {
          return 1
        }
      }
      return 0
    });
    return result || products;
  }


  const sanitizeInitialState = useCallback((raw: IPlatformStore): IPlatformStore => {
    const data = JSON.parse(JSON.stringify(raw));
    if(!data?.products?.list) return data;
     const newProductList: IProductRec [] = [];
     const comingSoonList: IProductRec [] = [];
      data?.products?.list?.forEach((item: any) => {
        const categoryList = item?.product?.category?.map?.((c: any) => c['_id']) || [];
        const territoryList = item?.product?.territory?.map?.((t: any) => t['_id']) || [];
        const providerID = item?.product?.provider?._id || '';
        const providerList = providerID ? [providerID] : [];
        const pasIntegrationList = item?.product?.pasIntegration?.map?.((pas: any) => pas['_id']) || [];
        const newItem = {
          ...item?.product,
          ctaUrl: item?.product?.slug?.current || '',
          type: "APPLICATION",
          title: item?.product?.name,
          imageurl: item?.product?.image?.asset?.url || "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg",
          description: item?.product?.description,
          PASIntegrated: getAppPASIntegration(item?.product?.pasIntegration),
          category: getAppCategories(item?.product?.category),
          uniqueReference: item?.product?.['_id'],
          shouldHaveAddButton: true,
          categoryArr: item?.product?.category,
          categoryList,
          territoryList,
          providerList,
          pasIntegrationList
        }
        if(item?.product?.isComingSoon) {
          comingSoonList.push(newItem);
        } else {
          newProductList.push(newItem);
        }
        newItem
      });
      data.products.list = sortProductsAlphabetically(newProductList);
      data.products.comingSoonList = sortProductsAlphabetically(comingSoonList);
      return data;
  }, [data])

  const initializeStore = useCallback(async (payload:any) => {
    const cleanedData = sanitizeInitialState(payload);
    dispatch({ type: ACTION_TYPES.INITIALIZE_STORE, payload: cleanedData})
  }, [data])

  useEffect(() => {
    !state?.store?.products && data?.products?.list && initializeStore(data);
  }, [data]);


  const getAppCategories = useCallback((data: IPlatformGroups[]): string => {
    return data?.map?.((ele: IPlatformGroups) => ele?.title)?.join?.(', ') || '';
  }, []);

  const getAppPASIntegration = useCallback((data: IPlatformGroups[]): string => {
    return data?.map?.((ele: IPlatformGroups) => ele?.title)?.join?.(', ') || '';
  }, []);


  const hideCategoryAndPAS = useCallback((data: any = []) => {
    return data?.map?.((item: any) => {
      return {
        ...item,
        shouldHaveAddButton: true,
        hideCategory: true,
        hidePASIntegrated: true,
      } as IPlatformCardProps
    })  as IPlatformCardProps[] || [];
  }, [state?.store]);

  const hideCategoryReadMoreAndPAS = useCallback((data: any = []) => {
    return data?.map?.((item: any) => {
      return {
        ...item,
        shouldHaveAddButton: true,
        hideCategory: true,
        hidePASIntegrated: true,
        hideButtons: true,
      } as IPlatformCardProps
    })  as IPlatformCardProps[] || [];
  }, [state?.store]);

  const getCategories = useCallback(() => {
  if(!state?.store?.categories?.list) return []
  const categories = state?.store?.categories?.list?.map?.((rec: any) => {
    const category: IPlatformGroups = rec?.category

    const obj = {
        type: "CATEGORY",
        title:  category?.name,
        description: category?.description?.[0]?.children?.[0]?.text,
        uniqueReference:  category["_id"],
        shouldHaveAddButton: false,
        icon: category?.image?.asset?.url || "https://res.cloudinary.com/dflmq4zxb/image/upload/v1634734446/accounting-systems_ufnmax.svg",
        ctaUrl: `/products/iplatform/search?category=${category["_id"]}`,
    }
    return obj
  })
    // sort categories by title
    categories.sort((a: any, b: any) => {
      if(a.title < b.title) return -1;
      if(a.title > b.title) return 1;
      return 0;
    })

    return categories || []
  }, [state?.store])

  const getMostPopularApp = useCallback(() => {
    const products = state?.store?.products?.list || [];
    const _prod = products as IProductRec[];
    const _product = _prod?.filter?.((item: IProductRec) => item?.mostPopularApp);
    return hideCategoryAndPAS(_product)
  }, [state?.store])

  const getComingSoonApp = useCallback(() => {
    const productObj = state?.store?.products as IProduct
    const products = productObj?.comingSoonList || [];
    return hideCategoryReadMoreAndPAS(products)
  }, [state?.store])

  const getRecentlyAddedApp = useCallback(() => {
    const products = state?.store?.products?.list || [];
    const _prod = products as IProductRec[];
    const recentlyAdded = _prod?.filter?.((item: IProductRec) => item?.recentlyAddedApp);
    return hideCategoryAndPAS(recentlyAdded) || [];
  }, [state?.store]);

  const sortChipsAlphabetically = (chips: ISelectedChip[]): ISelectedChip[] => {
    const result = chips?.sort?.((a, b) => {
      if(a?.text?.toLowerCase?.() && b?.text?.toLowerCase?.()) {
        if ( a?.text?.toLowerCase?.() < b?.text?.toLowerCase?.()) {
          return -1
        }
        if (a?.text?.toLowerCase?.() > b?.text?.toLowerCase?.()) {
          return 1
        }
      }
      return 0
    });
    return result || chips;
  }

  const getChips = useCallback((): IChipListArr => {
    const storeRecords: IPlatformStore = state?.store;
    const categoryList: ISelectedChip[] = storeRecords?.categories?.list?.map?.((rec: any) => {
      const category = rec?.category
      const obj: ISelectedChip = {
        category: category?.['_type'],
        text:  category?.name || '',
        id:  category["_id"],
      }
      return obj
    }) || [];

    const geographyList: ISelectedChip[] = storeRecords?.terriotories?.list?.map?.((rec: any) => {
      const territory = rec?.territory
      const obj = {
        category: territory?.['_type'],
        text:  territory?.title || '',
        id:  territory["_id"],
      }
      return obj
    }) || [];

    const policyList: ISelectedChip[] = storeRecords?.pasIntegrator?.list?.map?.((rec: any) => {
      const pas = rec?.pas
      const obj = {
        category: pas?.['_type'],
        text:  pas?.name || '',
        id:  pas["_id"],
      }
      return obj
    }) || [];

    const providerList: ISelectedChip[] = storeRecords?.provider?.list?.map?.((rec: any) => {
      const provider = rec?.provider
      const obj = {
        category: provider?.['_type'],
        text:  provider?.name || '',
        id:  provider["_id"],
      }
      return obj
    }) || [];

    const chipListArr: IChipListArr = [
      {
        category: "iPlatformProductCategory",
        list: sortChipsAlphabetically(categoryList),
        title: "By Category:"
      },
      {
        category: "iPlatformProductTerritory",
        list: sortChipsAlphabetically(geographyList),
        title: "By Geography:"
      },
      {
        category: "iPlatformProductPASIntegration",
        list: sortChipsAlphabetically(policyList),
        title: "By Policy Admin System:"
      },
      {
        category: "iPlatformProductProvider",
        list: sortChipsAlphabetically(providerList),
        title: "By Partner Provider:"
      }
    ];

    return chipListArr;
  }, [state?.store]);

  const getCategoryChipById = useCallback((id: string,) => {
    const storeCategories: ICategories = state?.store?.categories as ICategories;

    const category = storeCategories?.list?.find?.((rec: any) => rec?.category?.['_id'] === id);

    const chip: ISelectedChip = {
      category: category?.category?.['_type'] || '',
      text:  category?.category?.name || '',
      id:  category?.category["_id"] || '',
    }

    return chip;
  }, [state?.store]);

  const getFilteredProducts = useCallback((chipArr: ISelectedChip[]) => {
    const storeRecords: IPlatformStore = state?.store;
    const productArr = storeRecords?.products as IProduct;
    if(!chipArr?.length) return productArr?.list;
    const products: IProductRec[] = productArr?.list || [];

    // get the chips for each  catgeory
    const chipsHasCategoryList: ISelectedChip[] = [];
    const chipsHasTerritoryList: ISelectedChip[] = [];
    const chipsHasPASIntegrationList: ISelectedChip[] = [];
    const chipsHasProviderListList: ISelectedChip[] = [];

    // get the chips for each  catgeory
    chipArr.forEach((chip: ISelectedChip) => {
      if(chip?.category === "iPlatformProductCategory") {
        chipsHasCategoryList.push(chip);
      }
      if(chip?.category === "iPlatformProductTerritory") {
        chipsHasTerritoryList.push(chip);
      }
      if(chip?.category === "iPlatformProductPASIntegration") {
        chipsHasPASIntegrationList.push(chip);
      }
      if(chip?.category === "iPlatformProductProvider") {
        chipsHasProviderListList.push(chip);
      }
    })


    const filterProductsByChip = products?.filter?.((rec: IProductRec) => {
      const categoryList = rec?.categoryList || [];
      const territoryList = rec?.territoryList || [];
      const providerList = rec?.providerList || [];
      const pasIntegrationList = rec?.pasIntegrationList || [];

      // check if the product has the category for each chips
      const isCategory = chipsHasCategoryList?.length ? (chipsHasCategoryList?.every?.((chip: any) => categoryList?.some?.((category: any) => chip.id === category)) || false) : true;
      const isTerritory = chipsHasTerritoryList?.length ? (chipsHasTerritoryList?.every?.((chip: any) => territoryList?.some?.((territory: any) => chip.id === territory)) || false) : true;
      const isPASIntegration = chipsHasPASIntegrationList?.length ? (chipsHasPASIntegrationList?.every?.((chip: any) => pasIntegrationList?.some?.((pas: any) => chip.id === pas)) || false) : true;
      const isProvider = chipsHasProviderListList?.length ? (chipsHasProviderListList?.some?.((chip: any) => providerList?.some?.((provider: any) => chip.id === provider)) || false) : true;

      return isCategory && isTerritory && isPASIntegration && isProvider;
    });
    return filterProductsByChip || [];
  }, [state?.store]);




  const getSearchResult = useCallback((searchText: string) => {
    const storeRecords: IPlatformStore = state?.store;
    const productArr = storeRecords?.products as IProduct;
    const products: IProductRec[] = productArr?.list || [];
    const filterProductsBySearch = products?.filter?.((rec: IProductRec) => {
      const name = rec?.name || '';
      const description = rec?.description || '';
      const searchTextLowerCase = searchText.toLowerCase();
      const isName = name?.toLowerCase?.().includes(searchTextLowerCase) || false;
      const isDescription = description?.toLowerCase?.().includes(searchTextLowerCase) || false;
      return isName || isDescription;
    });
    return filterProductsBySearch || [];
  }, [state?.store]);


  const getProductByID = useCallback((id: string): IProductRec | undefined  => {
    const productArr = state?.store?.products as IProduct;
    const products: IProductRec[] = productArr?.list || [];
    const product = products?.find?.((rec: IProductRec) => rec?.['_id'] === id);
    return product;
  }, [state?.store]);

  const getCountries = useCallback(() => {
    const storeRecords: IPlatformStore = state?.store;
    const countryList: string [] = storeRecords?.terriotories?.list?.map?.((rec: any) => rec?.territory?.title) || [];
    return countryList || [];
  }, [state?.store]);

  const IPlatformRecords: IPlatformRecords = {
    store: state.store,
    getCategories,
    getMostPopularApp,
    getRecentlyAddedApp,
    getChips,
    getFilteredProducts,
    getProductByID,
    getCountries,
    getCategoryChipById,
    getSearchResult,
    getComingSoonApp,
  }

  return (
    <IPlatformContext.Provider value={IPlatformRecords}>
      {children}
    </IPlatformContext.Provider>
  )
}

const useIPlatformContext = () => {
  return useContext(IPlatformContext)
}

export { useIPlatformContext, IPlatformProvider }
