import { useStaticQuery, graphql } from "gatsby"

export const usePlatformPoltfolioData = () => {
    const data = useStaticQuery(
    graphql`
      query iPlatformProductPortfolio {
        terriotories: allSanityIPlatformProductTerritory {
          list: edges {
            territory: node {
              title
              _id
              _type
            }
          }
          totalCount
        }
        provider: allSanityIPlatformProductProvider {
          totalCount
          list: edges {
            provider: node {
              _id
              _type
              name
            }
          }
        }
        pasIntegrator: allSanityIPlatformProductPasIntegration {
          totalCount
          list: edges {
            pas: node {
              _type
              _id
              name: title
            }
          }
        }
        categories: allSanityIPlatformProductCategory {
          totalCount
          list: edges {
            category: node {
              _type
              _id
              name: title
              description {
              children {
                text
              }
              }
              image {
                asset {
                  url
                }
              }
            }
          }
        }

        products: allSanityIPlatformProduct {
          totalCount
          list: edges {
            product: node {
              _type
              _id
              isComingSoon
              name
              description
              billingMethodology
              contactEmail
              contactName
              pasIndependent
              mostPopularApp
              recentlyAddedApp
              isComingSoon
              category {
                _id
                description: _rawDescription
                image: _rawImage(resolveReferences: {maxDepth: 1})
                _type
                title
                importid
              }

              pasIntegration {
                _id
                _key
                _type
                importid
                title
              }

              detail: _rawProductDetail(resolveReferences: {maxDepth: 3})

              provider {
                _id
                _type
                _key
                name
              }

              video {
                _key
                _type
                autoplay
                controls
                disablekb
                loop
                modestbranding
                navanchor
                title
                url
              }

              territory {
                _type
                _key
                _id
                title
              }

              screenshots: _rawScreenshots(resolveReferences: {maxDepth: 4})

              image: _rawImage(resolveReferences: {maxDepth: 4})

              slug {
                current
              }

            }
          }
        }

      }
    `
  );

  return { data}
}
