import React from 'react'
import { SiteProvider } from './src/common/Context/app'
import "./src/common/styles/app.css";
import "animate.css"
import { AppListProvider } from './src/common/Context/AppListContext';
import { IPlatformProvider } from './src/common/Context/IPlatformContext';
import { ToastProvider } from './src/common/Context/ToastContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const wrapRootElement = ({ element }) => (
  <SiteProvider>
    <ToastProvider>
    <IPlatformProvider>
      <AppListProvider>
        <GoogleReCaptchaProvider reCaptchaKey="6LeygewiAAAAAHeFgEiHH1cikNmoLivi_jtUoIVp">
          {element}
        </GoogleReCaptchaProvider>
      </AppListProvider>
    </IPlatformProvider>
    </ToastProvider>
  </SiteProvider>
)